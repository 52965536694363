import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`Take it in. You are looking at poké paradise. This is where poké fantasies become reality.
This is our favorite spot at the moment, and honestly, we are having a hard time believing there might be better
out there, kinda like a toxic relationship. We rave about this place to everyone, so stop sitting here reading and go
check it out! Just do yourself a favor and DO NOT order the large. Or if you do, don't forget to wear the stretchy pants.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`Extra ginger? The limit does not exist.`}</li>
      <li parentName="ul">{`So much food, easily two meals worth.`}</li>
      <li parentName="ul">{`They hand out toppings like Oprah.`}</li>
      <li parentName="ul">{`Unique toppings and sauces galour.`}</li>
      <li parentName="ul">{`Compostable plastic bowls.`}</li>
      <li parentName="ul">{`Excellent service. `}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Compostable utensils? `}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      